windowSize();
moveElements();

$(document).ready(function(){
    
    if ($('.flexslider').length) {
        $('.flexslider').flexslider({
            animation: "fade",
            touch: true,
            controlNav: true,
            useCSS: false,
            video: false
        });
    }
    
    ($('.QCHIHome').length > 0) ? BannerFlexProp(1/3) : null;
    FeatureCard();
    
    $(window).resize(function() {
        if(this.resizeTO) clearTimeout(this.resizeTO);
        this.resizeTO = setTimeout(function() {
            moveElements();
            $(this).trigger('resizeEnd');    
        }, 250);
    });
});

$(window).load(function(){
    ($('.QCHIHome').length > 0) ? BannerFlexProp(1/3) : null;
});

////check for the orientation event and bind accordingly
if (window.DeviceOrientationEvent) {
//    window.addEventListener('orientationchange', WindowSize, moveElements, false);
}

function windowSize() {
	'use strict';
    var w = Math.max($(window).width(), window.innerWidth),
        type = 'Default';
    
    if (w >= 1540) {
        type = 'XL';
    } else if (w < 1540 && w >= 1280) {
        type = 'Large';
    } else if (w < 1280 && w >= 1024) {
        type = 'Medium';
    } else if (w < 1024 && w >= 440) {
        type = 'Small';
    } else {
        type = 'Default';
    }
  
    // change the css file of the tag with id="stl" and rel="stylesheet"
	$('body').removeClass('XL Large Medium Small Default').addClass(type);
}

function moveElements() {
    windowSize();
    MobileNav();
//    MobileLogo();
    MobileProxNav();
    MobileSearch();
    ($('.QCHIHome').length > 0) ? BannerFlexProp(1/3) : null;
    FeatureCard();
    equalHeight($('#LowerPanels .FeatureCardContent'));
}

function hasJS() {
    $('body').removeClass('no-js');
}

function MobileLogo() {
    if ($('body').hasClass('Small') || $('body').hasClass('Default')) {

        $(window).unbind().scroll(function() {
            if ($(window).scrollTop() > 40) {
                $('#LogoWrap img').attr('src', '/imgs/qchi%202016/logo-wide.png');
                $('body').addClass('wide-logo');
            } else {
                $('#LogoWrap img').attr('src', '/imgs/qchi%202016/logo-xl.png');
                $('body').removeClass('wide-logo');
            }
        });
        
    } else {
        $('#LogoWrap img').attr('src', '/imgs/qchi%202016/logo-xl.png');
        $('body').removeClass('wide-logo');
    }
}

function MobileNav() {
    if ($('body').hasClass('Medium') || $('body').hasClass('Large') || $('body').hasClass('XL')) {
        $('#MainNavWrap').appendTo($('#HeaderWrap'));
        $('#SecondaryNavWrap').appendTo($('#HeaderWrap'));
        $('#MobileNavWrap').hide();
    } else {
        $('#MainNavWrap').appendTo($('#MobileNavWrap'));
        $('#SecondaryNavWrap').appendTo($('#MobileNavWrap'));
        $('#MobileNavWrap').hide();
        
        $('#MobileMenuIcon').unbind().click(function(){
            $('#MobileNavWrap').toggle();
        });
    }
}

function MobileSearch() {
    if ($('body').hasClass('Default')) {
        $('#SearchWrap').appendTo($('#MobileNavWrap'));
    } else {
        $('#SearchWrap').insertAfter($('#LogoWrap'));
    }
}

function MobileProxNav() {
    if ($('body').hasClass('Medium') || $('body').hasClass('Large') || $('body').hasClass('XL')) {
        $('#ProximityNavWrap1').show();
    } else {
        $('#ProximityNavWrap1').hide();
        
        $('#ProximityToggle').unbind().click(function() {
            $('#ProximityNavWrap1').toggle();
        });
    }
}

function FeatureCard() {
    if ( $('body').hasClass('QCHIHome') && ($('body').hasClass('Medium') || $('body').hasClass('Large') || $('body').hasClass('XL')) ) {
        $('.FeatureCardImg').each(function(){
            var src = $(this).find('img').first().attr('src');
            $(this).css('background-image', 'url("' + src + '")');
            $(this).find('img').first().hide();
       });
    } else {
        $('.FeatureCardImg').each(function(){
            $(this).find('img').show();
            $(this).css('background-image','');
        });
    }
}

function BannerFlexProp(ratio) {
    $('#Banner .slides>li').each(function() {
        var ele = $(this).find('.PhotoTextWrapper');
        var second = $(this).find('img').first();
        
        if($('body').hasClass('Medium') || $('body').hasClass('Large') || $('body').hasClass('XL')) {
            var screen = Math.max($(window).width(), window.innerWidth);
            var page = $('#HeaderWrap').outerWidth();

            var percent =  100*(page * ratio + (screen - page)/2)/screen;

            ele.css('width', percent + "%");
            second.css('width', 100 - percent + "%;");

            var inner = 100 * (ele.outerWidth() - page*ratio + 24) / screen;
            ele.css('padding-left', inner + "%");
            
            $('.PhotoTextWrapper').has('.PhotoText').each(function(){
                var offset = ($(this).height() - $(this).find('.PhotoText').outerHeight())/2;
                $(this).find('.PhotoText').css('margin-top', offset + "px");
            });
        } else {
            ele.attr('style','');
            second.attr('style','');
            $('.PhotoText').attr('style','');
        }
    });
}

function equalHeight(group) { //make columns equal heights
    var tallest = 0;
    group.each(function () {
        var thisHeight = $(this).height();
        if (thisHeight > tallest) {
            tallest = thisHeight;
        }
    });
    group.css('min-height', tallest+1);
}

// ----------- MAIN DOC READY ------------------//
$(document).ready(function(){
    
    hasJS();
    
    // Hide unused site search tabs
    $('#VsMasterPage_MainContent_SearchNav > li').each(function() {
        var tab = $(this).find('a').text();
        
        if (tab === "Site Search" || tab === "Doctors" || tab === "Events" || tab === "Locations" ) {
            $(this).remove();
        }
    });
    $('#VsMasterPage_MainContent_SearchNav > li').first().find('a').click();
    
    // Responsive fb-page-plugin
    if ($('.fb-page').length > 0) {
        $(window).on('resizeEnd', function() {
            var container_width = $('.fb-page').parent().width();    
            $('.fb-page').parent().html('<div class="fb-page" data-href="https://www.facebook.com/quad-city-health-initiative" data-tabs="timeline" data-height="895" data-small-header="false" data-adapt-container-width="true" data-width="800" data-hide-cover="false" data-show-facepile="true"><div class="fb-xfbml-parse-ignore"><blockquote cite="https://www.facebook.com/quad-city-health-initiative"><a href="https://www.facebook.com/quad-city-health-initiative">Quad City Health Initiative</a></blockquote></div></div>');
            FB.XFBML.parse();   
        });
    }

    // Label facebook page plugin cmspanel
    $('.fb-page').closest('.cmspanel').addClass('has-fb-page');

    // Page Title on interior banner
    $('body.QCHIInterior .PageTitle').each(function(){
        var title = $(this).find('strong').text();
        $(this).html(title);
        $(this).css('display', 'block');
    });
    
     /* Smooth scroll back to top of page  
    ----------------------------------------------------------------- */
    $('#ToTop').click(function (event) {
        var duration = 500;

        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, duration);
        return false;
    });

    // Keeps accordions from jumping to the top of the page when accordion is really long
    // Reason for this hack: sticky nav was covering up the top of the accordion
    /*----------------------------------------------------------------- */
    $('.Accordion .Trigger').each(function () {
        $(this).mouseup(function () {
            $('html, body').animate({ scrollTop: $(window).scrollTop() });
        });
    });
    $('#DrDetail .Tabnav li').each(function () {
        $(this).mouseup(function () {
            $('html, body').animate({ scrollTop: $(window).scrollTop() });
        });
    });
    
    // YouTube Playlist function
    $('.PlaylistContainer').each(function () {

        $.each($(".PlaylistContainer > #ytvideo"), function (index, value) {
            var num = index + 1;
            $(value).attr("id", "ytvideo" + num);
        });

        $.each($(".PlaylistContainer > ul"), function (index, value) {
            var num = index + 1;
            $(value).attr("class", "ytplaylist" + num);
        });

        // You can have up to 8 YouTube playlists on a page. If you need more you will need to add more lines here.
        $("ul.ytplaylist1").ytplaylist({ addThumbs: true, holderId: 'ytvideo1' });
        $("ul.ytplaylist2").ytplaylist({ addThumbs: true, holderId: 'ytvideo2' });
        $("ul.ytplaylist3").ytplaylist({ addThumbs: true, holderId: 'ytvideo3' });
        $("ul.ytplaylist4").ytplaylist({ addThumbs: true, holderId: 'ytvideo4' });
        $("ul.ytplaylist5").ytplaylist({ addThumbs: true, holderId: 'ytvideo5' });
        $("ul.ytplaylist6").ytplaylist({ addThumbs: true, holderId: 'ytvideo6' });
        $("ul.ytplaylist7").ytplaylist({ addThumbs: true, holderId: 'ytvideo7' });
        $("ul.ytplaylist8").ytplaylist({ addThumbs: true, holderId: 'ytvideo8' });
    });

    /* Add VideoContainer around iframed YouTube videos
    ---------------------------------------------------------------- */
    $('iframe[src*="youtube.com"], iframe[src*="youtube-nocookie.com"]').each(function () {
        $(this).wrap('<div class="VideoContainer" />');
    });
    
    // ----------- TEMPLATE EXTRAS ------------------//
	
	
	
	
	
	// ----------- HACK LIST START ------------------//

	/* Nested Accordion Fix */
	$('.Accordion .Accordion').removeClass('Accordion').removeClass('AccordionReady').addClass('NestedAccordion').attr('role', 'tablist');
	$('.NestedAccordion>div').removeClass('Payload').addClass('Payload2').hide();
	$('.NestedAccordion>h1,.NestedAccordion>h2,.NestedAccordion>h3,.NestedAccordion>h4,.NestedAccordion>h5,.NestedAccordion>h6,.NestedAccordion>h7').removeClass('Trigger').addClass('Trigger2');
	$('.NestedAccordion .Trigger2').on('click', function () {
	if ($(this).hasClass('Active')) {
	    $(this).parent('.NestedAccordion').children('.Trigger2').removeClass('Active');
	    $(this).next('.Payload2').slideUp().removeClass('Open');
	} else {
	    $(this).parent('.NestedAccordion').children('.Trigger2').removeClass('Active');
	    $(this).addClass('Active').parent('.NestedAccordion').children('.Payload2').slideUp().removeClass('Open').css('display', 'none');
	    $(this).next('.Payload2').slideDown().addClass('Open');
	}
	});	
	
	/* Accordion Keyboard Accessibility Fix */
	$('.Accordion .Trigger').each(function() {
		var yourDiv = $(this);
		var yourSpan = $('<button class="Trigger" role="tab" aria-selected="false" tabindex="0" />');
		yourDiv.before(yourSpan);
		yourSpan.append(yourDiv.children());
		yourDiv.remove();
	});
	$('.Accordion .Trigger2').each(function() {
		var yourDiv = $(this);
		var yourSpan = $('<button class="Trigger2" role="tab" aria-selected="false" tabindex="0" />');
		yourDiv.before(yourSpan);
		yourSpan.append(yourDiv.children());
		yourDiv.remove();
	});

	// add aria roles to accordions
	$('.Accordion').each(function() {
		$(this).attr('role', 'tablist');
	});
	$('.Accordion .Payload').each(function() {
		$(this).attr('role', 'tabpanel').attr('aria-expanded', 'false').attr('aria-hidden', 'true');
	});
	$('.Accordion.StartOn1').each(function() {
		$(this).find('.Trigger:first').addClass('Active').attr('aria-selected', 'true');
		$(this).find('.Payload:first').attr('aria-expanded', 'true').attr('aria-hidden', 'false');
	});
	
	// add aria roles to tabs
	if ($('.TabContainer').length) {
		$('.TabContainer').each(function() {
			$(this).find('.Tabnav').attr('role', 'tablist');
			$(this).find('.Tabnav li').attr('role', 'presentation');
			$(this).find('.Tabnav li a').attr('aria-controls', 'tabname').attr('role', 'tab').attr('aria-selected', 'false').attr('tabindex', '0');
			$(this).find('.Tabnav li.Tabactive a').attr('aria-selected', 'true');
			
			$(this).find('.Tab').attr('role', 'tabpanel').attr('aria-hidden', 'true').attr('aria-expanded', 'false').attr('aria-labelledby', 'tabname-tab');
			$(this).find('.Tab:visible').attr('aria-hidden', 'false').attr('aria-expanded', 'true');
		
			$('.Tabnav li a').click(function () {
				$('.Tabnav li a').attr('aria-controls', 'tabname').attr('role', 'tab').attr('aria-selected', 'false').attr('tabindex', '0');
				$(this).attr('aria-selected', 'true');
				
				$('.Tab').attr('aria-hidden', 'true').attr('aria-expanded', 'false');
				
				setTimeout(function(){
					$('.Tab:visible').attr('aria-hidden', 'false').attr('aria-expanded', 'true');
				},500);
				
			});
			
		});	
	};
	
	// trigger window size to allow for grid layout to be used here so it calculates the height again.
	$('.Accordion .Trigger, .Accordion .Trigger2, .Tabnav a').on('click', function(e) {
		$('.Accordion .Trigger, .Accordion .Trigger2').attr('aria-selected', 'false');
		$(this).attr('aria-selected', 'true');
		e.preventDefault();
	});

	// ----------- HACK LIST END ------------------//

});